import React, { useEffect, useState } from 'react';
import app from './firebaseConfig'; // Ensure correct path
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore, collection, getDocs, query, where, orderBy, limit, startAfter, doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import './DashboardPage.css'; // Ensure correct path
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faBars } from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const DashboardPage = () => {
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [activeTab, setActiveTab] = useState('Active');
  const [note, setNote] = useState('');
  const [hoverText, setHoverText] = useState('');
  const [isMobileView, setIsMobileView] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [lastVisibleDoc, setLastVisibleDoc] = useState(null);
  const [uploadFile, setUploadFile] = useState(null); // State for file upload
  const navigate = useNavigate();
  const auth = getAuth(app);
  const firestore = getFirestore(app);
  const storage = getStorage(app);
  const MapMarker = ({ text }) => <FontAwesomeIcon icon={faMapMarkerAlt} title={text} />;
  const [mapCenter, setMapCenter] = useState({ lat: 59.95, lng: 30.33 }); // Default center

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location.href = 'https://bridgifymail.com';
      } else {
        const uid = user.uid;
        const docCollection = collection(firestore, uid);

        const q = query(docCollection, orderBy('timestamp', 'desc'), limit(10));

        getDocs(q)
          .then(querySnapshot => {
            const allDocs = [];
            querySnapshot.forEach(doc => {
              allDocs.push({ id: doc.id, ...doc.data() });
            });
            if (activeTab === 'Active') {
              setDocuments(allDocs.filter(doc => !doc.status));
            } else if (activeTab === 'Archived') {
              setDocuments(allDocs.filter(doc => doc.status));
            }
          })
          .catch(error => {
            console.error("Error fetching documents: ", error);
            // You can add more sophisticated error handling here
          });
      }
    });
  }, [auth, firestore, navigate, activeTab]);

  const handleLogout = async () => {
    await signOut(auth);
    window.location.href = 'https://bridgifymail.com';
  };

  const selectDocument = doc => {
    setSelectedDoc(doc);
    setNote(doc.notes || '');
    setHoverText('');
    if (doc.Address) {
      setMapCenter({ lat: doc.Address.latitude, lng: doc.Address.longitude });
    }
  };  

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNoteChange = (e) => {
    setNote(e.target.value);
  };

  const handleNoteSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(firestore, auth.currentUser.uid, selectedDoc.id);
    await updateDoc(docRef, { notes: note });
  };

  const handleAccept = async () => {
    const docRef = doc(firestore, auth.currentUser.uid, selectedDoc.id);
    await updateDoc(docRef, { status: 'accepted' });
  };

  const handleReject = async () => {
    const docRef = doc(firestore, auth.currentUser.uid, selectedDoc.id);
    await updateDoc(docRef, { status: 'rejected' });
  };

  // File upload handler
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setUploadFile(file);
    } else {
      // Handle non-PDF files or reset
      setUploadFile(null);
    }
  };

  const handleFileUpload = async () => {
    if (uploadFile && auth.currentUser) {
      const uid = auth.currentUser.uid;
      const fileRef = ref(storage, `/PDF/${uid}/${uploadFile.name}`);
      const metadata = {
        customMetadata: {
          'userUid': uid
        }
      };
  
      try {
        await uploadBytes(fileRef, uploadFile, metadata);
        const downloadURL = await getDownloadURL(fileRef);
        console.log("File uploaded successfully. URL:", downloadURL);
        // Additional logic can be added here as needed
      } catch (error) {
        console.error("Error uploading file: ", error);
        // Error handling logic can be added here
      }
    } else {
      // Logic in case there is no file selected or the user is not authenticated
      console.error("No file selected or user not authenticated");
    }
  };

  const boldRedFlagWords = text => {
    const redFlagWords = ['hi', 'there'];
    return redFlagWords.reduce((acc, word) => {
      const regex = new RegExp(`\\b${word}\\b`, 'gi');
      return acc.replace(regex, `<strong>${word}</strong>`);
    }, text);
  };

  const loadMoreDocuments = async () => {
    const uid = auth.currentUser.uid;
    const docCollection = collection(firestore, uid);

    let queryConstraints = [orderBy('timestamp', 'desc'), limit(10), startAfter(lastVisibleDoc)];
    if (activeTab === 'Active') {
      queryConstraints.unshift(where('status', '==', null));
    } else if (activeTab === 'Archived') {
      queryConstraints.unshift(where('status', '!=', null));
    }

    const q = query(docCollection, ...queryConstraints);

    const newDocs = await getDocs(q);
    const docsArray = [];
    let lastVisible = null;
    newDocs.forEach(doc => {
      docsArray.push({ id: doc.id, ...doc.data() });
      lastVisible = doc;
    });

    setDocuments([...documents, ...docsArray]);
    setLastVisibleDoc(lastVisible);
  };

  return (
    <div className="dashboard">
      {isMobileView && (
        <button className="hamburger-button" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      )}
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button onClick={toggleSidebar} className="close-sidebar">Close</button>
        <div className="tabs">
          <div onClick={() => setActiveTab('Active')} className={`tab ${activeTab === 'Active' ? 'active' : ''}`}>Active</div>
          <div onClick={() => setActiveTab('Archived')} className={`tab ${activeTab === 'Archived' ? 'active' : ''}`}>Archived</div>
        </div>
        {activeTab === 'Active' && (
          <>
            <input type="file" onChange={handleFileChange} accept=".pdf" />
            <button className="upload-button" onClick={handleFileUpload}>Upload</button>
          </>
        )}
        {documents.map(doc => (
          <div key={doc.id} className="sidebar-item" onClick={() => selectDocument(doc)}>
            <strong>{doc.Name}</strong><br />
            <small>
              {doc.timestamp && new Date(doc.timestamp.seconds * 1000).toLocaleDateString()} - {Object.keys(doc).length} items
            </small>
          </div>
        ))}
      </div>
      <div className="content">
        {selectedDoc && (
          <>
            <h1>{selectedDoc.Name}</h1>
            <div className="actions">
              <button className="accept-button" onClick={handleAccept}>
                <FontAwesomeIcon icon={faCheckCircle} /> Accept
              </button>
              <button className="reject-button" onClick={handleReject}>
                <FontAwesomeIcon icon={faTimesCircle} /> Reject
              </button>
            </div>
            <form onSubmit={handleNoteSubmit}>
              <textarea value={note} onChange={handleNoteChange}></textarea>
              <button type="submit">Save Note</button>
            </form>
            <div className="text-card" onMouseOver={() => setHoverText(selectedDoc.Longtext)} onMouseOut={() => setHoverText('')}>
              <p dangerouslySetInnerHTML={{ __html: boldRedFlagWords(hoverText || selectedDoc.Shorttext) }}></p>
            </div>
  
            {selectedDoc.Medlist && (
              <div className="card">
                <h2>Medications</h2>
                <ul>
                  {Object.values(selectedDoc.Medlist).map((med, index) => (
                    <li key={index} title={med.purpose}>{med.name}</li>
                  ))}
                </ul>
              </div>
            )}
  
            {selectedDoc.Diagnoses && (
              <div className="card diagnoses">
                <h2>Diagnoses</h2>
                <ul>
                  {selectedDoc.Diagnoses.map((diagnosis, index) => (
                    <li key={index}>{diagnosis}</li>
                  ))}
                </ul>
              </div>
            )}
  
            {selectedDoc.Timeline && (
              <div className="card timeline-card">
                <h2>Timeline</h2>
                {Object.entries(selectedDoc.Timeline).map(([key, value]) => (
                  <div key={key}>
                    <strong>{new Date(value.date.seconds * 1000).toLocaleDateString()}</strong>
                    <p>{value.desc}</p>
                  </div>
                ))}
              </div>
            )}
  
            {selectedDoc.Address && (
              <div className="card map-card">
                <h2>Location Map</h2>
                <div style={{ height: '300px', width: '100%' }}>
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'YOUR_GOOGLE_MAPS_API_KEY' }} // Replace with your API key
                    defaultCenter={mapCenter}
                    defaultZoom={11}
                  >
                    <MapMarker
                      lat={selectedDoc.Address.latitude}
                      lng={selectedDoc.Address.longitude}
                      text={selectedDoc.Name}
                    />
                  </GoogleMapReact>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <button className="logout-button" onClick={handleLogout}>Logout</button>
      {documents.length > 10 && (
        <button onClick={loadMoreDocuments}>View More</button>
      )}
    </div>
  );
};

export default DashboardPage;
