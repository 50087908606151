
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyAeaIfLdvY3GfUGZlXaudj-Cb9vDQfK_1o",
  authDomain: "bridgify-intaike.firebaseapp.com",
  projectId: "bridgify-intaike",
  storageBucket: "bridgify-intaike.appspot.com",
  messagingSenderId: "334378026810",
  appId: "1:334378026810:web:4a5522e135d69dba803203",
  measurementId: "G-EB43V4YEX3"
};

const app = initializeApp(firebaseConfig);

export default app;

